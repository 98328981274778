import { Injectable } from '@angular/core';
import { MessageService } from 'primeng/api';
@Injectable({
  providedIn: 'root'
})
export class ToastService {

  constructor(private messageService:MessageService) { }


  showSuccess(mesg: string) {
    this.messageService.add({ severity: 'success', summary: 'Success', detail: mesg });
  }

  showError(mesg: string) {
    this.messageService.add({ severity: 'error', summary: '', detail: mesg });
  }

  showInfo(mesg: string) {
    this.messageService.add({ severity: 'info', summary: '', detail: mesg });
  }
}
